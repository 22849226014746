export enum ActionType {
  NON_CLINICAL = "nonClinical",
  WORK_WITH_FRIENDS = "workWithFriends",
  FRIEND_INVITES = "friendInvites",
  BOOK_WITH_ME = "bookWithMe",
  OPEN_MAP_VIEW = "openMapView",
  WORKER_AVAILABILITY = "worker-availability",
  OPEN_BLOCKS = "openBlocks",
  OPEN_JOBS = "openJobs",
}

export const DEEP_LINK_WWF_CANT_ADD_FRIEND_ALERT_PROPS = {
  header: "Cannot add friend",
  message: "You need to complete the onboarding before you can start adding friends",
};
