import { isDefined } from "@clipboard-health/util-ts";
import { IonAlert, IonLoading, IonPage } from "@ionic/react";
import { Box } from "@mui/material";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { SEARCH_MODE } from "@src/constants";
import { Facility } from "@src/lib/interface";
import { FC, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { fetchFacilityProfile } from "./api";
import { FacilityDetails } from "../dayView/facilityDetails";
import { TabRouterPath } from "../routing/constant/tabRoute";

const FacilityDetailPage: FC<{}> = () => {
  const { facilityId } = useParams<{ facilityId: string }>();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const isExtraTimeCardVisible = searchParams.get("isExtraTimeCardVisible") === "true";
  const hcfProfileSource = searchParams.get("hcfProfileSource") ?? undefined;
  const searchMode = (searchParams.get("searchMode") as SEARCH_MODE) ?? undefined;

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [facilityDetails, setFacilityDetails] = useState<Facility>();

  /**
   * FIXME
   *
   * Move to useGetQuery. However, it's not as straightforward as in another
   * ocassions. In this case, since we have the "projection" argument when fetching
   * a facility profile, that can change the data that the server returns. This
   * prevents us from typing it correctly because we would have to mark every
   * property as optional which doesn't match reality.
   */
  useEffect(() => {
    const fetchProfileDetails = async () => {
      setLoading(true);
      const facilityInfo = await fetchFacilityProfile(facilityId);
      if (!facilityInfo) {
        setError(true);
      } else {
        setFacilityDetails(facilityInfo);
      }
      setLoading(false);
    };
    fetchProfileDetails();
  }, [facilityId]);

  return (
    <IonPage>
      <PageWithHeader
        containerVariant="without-margin"
        appBarHeader={
          <AppBarHeader
            title={facilityDetails?.name ?? "Workplace Details"}
            leftCta={<BackButtonLink defaultBackTo={TabRouterPath.OPEN_SHIFTS} />}
          />
        }
      >
        <Box>
          {isLoading && !isDefined(facilityDetails) && (
            <IonLoading isOpen={isLoading} message="Loading workplace details..." />
          )}
          {!isLoading && !isDefined(facilityDetails) && (
            <IonAlert
              isOpen={isError}
              onDidDismiss={() => setError(false)}
              header="Error"
              subHeader="No such workplace is available"
              message="The workplace url seems to be invalid."
              buttons={["OK"]}
            />
          )}

          {facilityDetails && (
            <FacilityDetails
              onClose={() => {}}
              facility={facilityDetails}
              showHeader={false}
              searchMode={searchMode}
              displayExtraTimePayCard={isExtraTimeCardVisible}
              hcfProfileSource={hcfProfileSource}
            />
          )}
        </Box>
      </PageWithHeader>
    </IonPage>
  );
};

export { FacilityDetailPage };
